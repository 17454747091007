var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "keyword", label: "输入:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "小区名称/会员信息" },
                    model: {
                      value: _vm.searchForm.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "keyword", $$v)
                      },
                      expression: "searchForm.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("searchForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.handleSearch },
                      model: {
                        value: _vm.searchForm.filter.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm.filter, "status", $$v)
                        },
                        expression: "searchForm.filter.status"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部")
                      ]),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("未启用")
                      ]),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("启用")
                      ]),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("禁止")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("小区员工")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.dataList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "property_name",
                  label: "小区名称",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "员工信息",
                  "header-align": "center",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " + _vm._s(scope.row.member_realname)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.member_mobile) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "role_type",
                  label: "角色类型",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.RoleType(scope.row.role_type)) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_add",
                  label: "权限",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.is_add == 1
                              ? _c("el-tag", [_vm._v("添加")])
                              : _vm._e(),
                            scope.row.is_edit == 1
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("编辑")
                                ])
                              : _vm._e(),
                            scope.row.is_delete == 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("删除")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "last_login_ip",
                  label: "最近登陆IP",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "last_login_time",
                  label: "最近登录时间",
                  "header-align": "center",
                  align: "center",
                  width: "125px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "login_times",
                  label: "登录次数",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  "header-align": "center",
                  align: "center",
                  width: "60px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "span",
                            [
                              scope.row.status == 0
                                ? _c("el-tag", { attrs: { type: "info" } }, [
                                    _vm._v(
                                      "\n                未启用\n              "
                                    )
                                  ])
                                : _vm._e(),
                              scope.row.status == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(
                                      "\n                启用\n              "
                                    )
                                  ])
                                : _vm._e(),
                              scope.row.status == 2
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(
                                      "\n                禁止\n              "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "添加时间",
                  "header-align": "center",
                  align: "center",
                  width: "125px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleDel(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage>0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.getTableData
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.staffDialog,
            title: _vm.textMap[_vm.dialogTitle]
          },
          on: {
            "update:visible": function($event) {
              _vm.staffDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                "label-position": "right",
                "label-width": "130px",
                model: _vm.dialogForm,
                rules: _vm.rules
              }
            },
            [
              _vm.dialogTitle === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "小区：", prop: "property_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入小区关键词搜索",
                            "remote-method": _vm.remoteProperty,
                            loading: _vm.propertyLoading,
                            clearable: ""
                          },
                          model: {
                            value: _vm.dialogForm.property_id,
                            callback: function($$v) {
                              _vm.$set(_vm.dialogForm, "property_id", $$v)
                            },
                            expression: "dialogForm.property_id"
                          }
                        },
                        _vm._l(_vm.propertyOptions, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.name))
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogTitle === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "小区：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.dialogForm.property_name,
                          callback: function($$v) {
                            _vm.$set(_vm.dialogForm, "property_name", $$v)
                          },
                          expression: "dialogForm.property_name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogTitle === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "用户：", prop: "user_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入手机号或名字搜索",
                            "remote-method": _vm.remoteMember,
                            clearable: "",
                            loading: _vm.memberLoading
                          },
                          model: {
                            value: _vm.dialogForm.user_id,
                            callback: function($$v) {
                              _vm.$set(_vm.dialogForm, "user_id", $$v)
                            },
                            expression: "dialogForm.user_id"
                          }
                        },
                        _vm._l(_vm.memberOptions, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                label:
                                  item.nickname || item.realname || item.mobile,
                                value: item.id
                              }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(
                                  _vm._s(
                                    item.nickname ||
                                      item.realname ||
                                      item.mobile
                                  )
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px"
                                  }
                                },
                                [_vm._v(_vm._s(item.mobile))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogTitle === "update"
                ? _c("el-form-item", { attrs: { label: "用户：" } }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.dialogForm.member_realname)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.dialogForm.member_mobile) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色类型：",
                    prop: "role_type",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "角色类型" },
                      model: {
                        value: _vm.dialogForm.role_type,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "role_type", $$v)
                        },
                        expression: "dialogForm.role_type"
                      }
                    },
                    _vm._l(_vm.roleType, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限：" } },
                [
                  _c("el-checkbox", {
                    attrs: { label: "添加", size: "medium" },
                    model: {
                      value: _vm.dialogForm.is_add,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "is_add", $$v)
                      },
                      expression: "dialogForm.is_add"
                    }
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "编辑", size: "medium" },
                    model: {
                      value: _vm.dialogForm.is_edit,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "is_edit", $$v)
                      },
                      expression: "dialogForm.is_edit"
                    }
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "删除", size: "medium" },
                    model: {
                      value: _vm.dialogForm.is_delete,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "is_delete", $$v)
                      },
                      expression: "dialogForm.is_delete"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.dialogForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "status", $$v)
                        },
                        expression: "dialogForm.status"
                      }
                    },
                    [_vm._v("待启用")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.dialogForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "status", $$v)
                        },
                        expression: "dialogForm.status"
                      }
                    },
                    [_vm._v("启用")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.dialogForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.dialogForm, "status", $$v)
                        },
                        expression: "dialogForm.status"
                      }
                    },
                    [_vm._v("禁止登陆")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { "max-width": "500px" },
                    attrs: { clearable: "", placeholder: "" },
                    model: {
                      value: _vm.dialogForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "remark", $$v)
                      },
                      expression: "dialogForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-container" },
            [
              _vm.dialogTitle === "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e(),
              _vm.dialogTitle === "update"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: { click: _vm.handleEditBtn }
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.staffDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }