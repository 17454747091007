import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchPropertyStaffList, addPropertyStaff, updatePropertyStaff, delPropertyStaff } from "@/api/charge";
import { fetchMemberList } from "@/api/member";
import { fetchPropertyList } from "@/api/charge";
import Pagination from "@/components/Pagination";
export default {
  name: "propertyStaff",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: ""
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      total: 0,
      agentLoading: false,
      agentOptions: [],
      dialogForm: {
        property_id: '',
        property_name: '',
        user_id: '',
        member_realname: '',
        member_mobile: '',
        role_type: '',
        is_add: true,
        is_edit: true,
        is_delete: true,
        status: '1',
        remark: ''
      },
      staffDialog: false,
      dialogTitle: '',
      textMap: {
        update: "编辑小区员工",
        create: "添加小区员工"
      },
      roleType: [{
        value: 'admin',
        label: "物业管理员"
      }, {
        value: 'staff',
        label: "物业员工"
      }, {
        value: 'agent',
        label: "代理商"
      }],
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      submitLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      rules: {
        property_id: [{
          required: true,
          message: "小区必填",
          trigger: "blur"
        }],
        user_id: [{
          required: true,
          message: "用户必填",
          trigger: "blur"
        }],
        role_type: [{
          required: true,
          message: '请选择角色类型',
          trigger: "blur"
        }],
        remark: [{
          required: true,
          message: "备注必填",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchPropertyStaffList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    resetPayee: function resetPayee() {
      this.dialogForm = {
        property_id: '',
        property_name: '',
        user_id: '',
        member_realname: '',
        member_mobile: '',
        role_type: '',
        is_add: true,
        is_edit: true,
        is_delete: true,
        status: '1',
        remark: ''
      };
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      this.resetPayee();
      this.staffDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this2.$refs["dialogForm"].clearValidate();
      });
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.staffDialog = true;
      this.resetPayee();
      this.dialogForm = {
        id: data.id,
        property_id: data.property_id,
        property_name: data.property_name,
        user_id: data.user_id,
        member_realname: data.member_realname,
        member_mobile: data.member_mobile,
        is_add: data.is_add == 1 ? true : false,
        is_edit: data.is_edit == 1 ? true : false,
        is_delete: data.is_delete == 1 ? true : false,
        status: data.status + '',
        role_type: data.role_type,
        remark: data.remark
      };
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = Object.assign({}, _this3.dialogForm);
          updatePropertyStaff(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: "编辑成功"
            });

            _this3.staffDialog = false;
            _this3.submitLoading = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.submitLoading = false;

            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleDel: function handleDel(row) {
      var _this4 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u5C0F\u533A\u5458\u5DE5";
      var title = "删除小区员工";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var id = row.id;
        delPropertyStaff(id).then(function (res) {
          console.log(res);

          _this4.$message({
            type: "success",
            message: "删除小区员工成功"
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleSubmit: function handleSubmit() {
      var _this5 = this;

      if (this.submitLoading == true) {
        return;
      }

      this.submitLoading = true;
      addPropertyStaff(this.dialogForm).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this5.$message({
          type: "success",
          message: "添加小区员工成功"
        });

        _this5.staffDialog = false;
        _this5.submitLoading = false;

        _this5.getTableData();
      }).catch(function (err) {
        _this5.submitLoading = false;

        _this5.$message({
          type: "error",
          message: err.data["message"]
        });
      });
      return false;
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this6 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this6.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            console.log(res);
            _this6.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this7 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this7.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this7.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    remoteAgent: function remoteAgent(query) {
      var _this8 = this;

      console.log("agent remote");

      if (query !== "") {
        this.agentLoading = true;
        setTimeout(function () {
          _this8.agentLoading = false;
          var data = {
            keyword: query
          };
          fetchAgentList(data).then(function (res) {
            console.log(res);
            _this8.agentOptions = res.data;
          });
        }, 100);
      } else {
        this.agentOptions = [];
      }
    },
    RoleType: function RoleType(type) {
      var label = '';
      this.roleType.forEach(function (item, key) {
        console.log(item);

        if (item.value == type) {
          label = item.label;
        }
      });
      return label;
    }
  }
};